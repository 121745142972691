import React from 'react';
import { Header } from '../Header';
import { Billing } from './';
import { Footer } from '../Footer';
import { Loading } from '../Loading';

export const BillingPage = ({ loading, dataBilling }) => {

  const { isPremiumPro = false } = dataBilling;

  return (
  <>
    <Header isPremiumPro = {isPremiumPro}/>
    {!loading ? <Billing dataBilling={dataBilling} /> : <Loading />}
    <Footer />
  </>

)};
