import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
  min-height: 400px;
  background: url(/bg-header.svg) no-repeat center center;
  background-size: cover;
  position: absolute;
  z-index: 0;
  top: 0;
`;

const WrapperPro = styled(Wrapper)`
  background: #404040;`

const Container = styled.div`
  max-width: 1143px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 112px 0;
  margin: 0 auto;
`;

const Page404 = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  p {
    font-size: 40px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #424242;
    margin-bottom: 30px;
  }

  img {
    max-width: 758px;
    width: 100%;
  }

  @media not print {
    @media (max-width: 600px) {
      p {
        font-size: 24px;
      }
    }
  }
`;

export { Wrapper, Container, Page404, WrapperPro };
